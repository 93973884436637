
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import parse from "html-react-parser";
import React from "react";
import DemoButton from '../demoButton/demoButton';
import * as s from "./iconItemsBlock.module.css";

const IconItemsBlock = ({ block, scrollToForm }) => {
  const bp = useBreakpoint();

  return (
    <>
      <section className={`${s.block} ${block?.orangeBoxes ? s.orangeItems : ''}`}>
        <div className="container">
          <div className={`row`}>
            {!!block?.title && <div className='col-12'>
              <h2 className={`gradient-title ${s.title}`}>{parse(block?.title)}</h2>
            </div>}
          </div>
          <div className={`row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center ${s.items}`}>
            {block?.items?.map((item, i) => {
              return <div key={i} className={`col`}>
                <div className={`${s.item}`}>
                  {!!item?.svgIconCode && <div className={s.itemIcon}>{parse(item?.svgIconCode)}</div>}
                  {!!item?.title && <div className={s.itemTitle}>{parse(item?.title)}</div>}
                  {!!item?.description && <div className={s.itemDescription}>{parse(item?.description)}</div>}
                </div>
              </div>
            })}
          </div>
          {(!!block?.button?.url) && <div className="d-flex justify-content-center mt-4 px-0 mt-xl-5 pt-xl-3">
            {'#contactForm' == block?.button?.url
              ? <DemoButton scrollToForm={scrollToForm} classes={['demo-button']}>{block?.button?.title}</DemoButton>
              : <Link to={block?.button?.url} className={'demo-button'} target={block?.button?.target}>{block?.button?.title}</Link>}
          </div>}
        </div>
      </section>
    </>
  );
};

export default IconItemsBlock;
