
import { Link } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import parse from "html-react-parser";
import React from "react";
import DemoButton from "../demoButton/demoButton";
import * as s from "./contentWithStats.module.css";

const ContentWithStats = ({ block, scrollToForm }) => {
  const bp = useBreakpoint();


  return (
    <>
      <section className={`${s.contentWithImageBlock} ${!!block?.vertical ? s.vertical : ''}`}>
        <div className="container">
          <div className={`row align-items-center`}>
            <div className="col-lg-6">
              {!!block?.title && <h2 className={`gradient-title ${s.title}`}>{parse(block?.title)}</h2>}
              {!!block?.description && <div className={`${s.description}`}>{parse(block?.description)}</div>}
              {(!!block?.button?.url) && <>
                {'#contactForm' == block?.button?.url
                  ? <DemoButton scrollToForm={scrollToForm} classes={['demo-button']}>{block?.button?.title}</DemoButton>
                  : <Link to={block?.button?.url} className={'demo-button'} target={block?.button?.target}>{block?.button?.title}</Link>}
              </>}
            </div>
            <div className="col-lg-6 text-center">
              {block?.stats?.length > 0 && <div className={`row row-cols-1 row-cols-lg-2 ${s.stats}`}>
                {block?.stats?.map((stat, i) => {
                  return <div className={`col-lg-6 ${s.stat}`} key={i}>
                    {!!stat.value && <div className={`${s.value}`}>{parse(stat.value)}</div>}
                    {!!stat.label && <div className={`${s.label}`}>{parse(stat.label)}</div>}
                  </div>
                })}
              </div>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentWithStats;
