import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import Slider from 'react-slick';
import DemoButton from "../build/demoButton/demoButton";
import * as s from "./image-text-slider.module.css";

const ImageTextSlider = ({ block, scrollToForm }) => {

  const [slider, setSlider] = React.useState(null);
  const sliderSettings = {
    dots: false,
    arrows: true,
    slidesToShow: 1,
    infinite: false,
    swipeToSlide: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
  };

  const next = () => {
    if (null !== slider)
      slider.slickNext();
  }
  return (
    <section className={`${s.block}`}>
      {!!block?.title && <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className={`gradient-title ${s.title}`}>{parse(block?.title)}</h2>
          </div>
        </div>
      </div>}
      <Slider
        ref={c => { setSlider(c) }}
        {...sliderSettings}
        className={s.slider}
      >
        {block?.items?.length && block?.items.map((item, i) => {
          let image = {
            data: item?.image?.localFile?.childImageSharp?.gatsbyImageData,
            alt: item?.image?.altText || ``,
            src: item?.image?.localFile?.publicURL
          }

          let titleClasses = [s.imageTextTitle];
          let imageClasses = [
            "col-12",
            "d-flex",
            "justify-content-center",
            "align-items-center",
            "col-lg-6",
            "order-1"
          ];
          let textClasses = ["col-12", "col-lg-6", "order-2"];
          if (item?.imagePosition === "left") {
            imageClasses.push("order-lg-1");
            imageClasses.push("justify-content-xl-start");
            textClasses.push("order-lg-2");
          }
          if (item?.imagePosition === "right") {
            imageClasses.push("order-lg-2");
            imageClasses.push("justify-content-xl-end");
            textClasses.push("order-lg-1");
          }

          return (
            <div key={`image-text-slider-item-${i}`}>
              <div className={`container h-100 `}>
                <div className={` ${s.sliderWrapper}`}>
                  <div className="row h-100 align-items-center justify-content-between" style={{ rowGap: '24px' }}>
                    <div className={imageClasses.join(" ")}>
                      {/* {!!image?.data &&
                        <GatsbyImage
                          placeholder="none"
                          loading="eager"
                          image={image.data}
                          alt={image.alt}
                          className="mw-100" />
                      } */}
                      {!!image?.src && <img src={image?.src} className="mw-100" loading="lazy" />}
                    </div>
                    <div className={textClasses.join(" ")}>
                      {!!item?.title && <h3 className={titleClasses.join(" ")}>{parse(item?.title)}</h3>}
                      {!!item?.description && <div className={s.imageTextDescription}>{parse(item?.description)}</div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>

      {(!!block?.button?.url) && <>
        <div className="container mt-4 pt-lg-2">
          <div className="row">
            <div className="col d-flex justify-content-center">
              {'#contactForm' == block?.button?.url
                ? <DemoButton scrollToForm={scrollToForm} classes={['demo-button']}>{block?.button?.title}</DemoButton>
                : <Link to={block?.button?.url} className={'demo-button'} target={block?.button?.target}>{block?.button?.title}</Link>}
            </div>
          </div>
        </div>
      </>}
    </section>
  );
};



const ArrowLeft = (props) => {
  const { className, style, onClick, styles } = props;
  return <button
    className={`${className} custom-button`}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M11.3359 12.59L6.80332 8L11.3359 3.41L9.94053 2L4.0026 8L9.94053 14L11.3359 12.59Z" fill="white" />
    </svg>
  </button>
}

const ArrowRight = (props) => {
  const { className, style, onClick, styles } = props;
  return <button
    className={`${className} custom-button`}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path d="M5.16406 12.59L9.69668 8L5.16406 3.41L6.55947 2L12.4974 8L6.55947 14L5.16406 12.59Z" fill="white" />
    </svg>
  </button>
}

export default ImageTextSlider;
