import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import YoutubeVideo from './../../youtubeVideo/youtubeVideo';
import * as s from "./hero.module.css";

const Hero = ({ block }) => {

  const bp = useBreakpoint();

  const desktopImage = {
    data: block.desktopImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.desktopImage?.altText || ``,
  };

  const mobileImage = {
    data: block.mobileImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.mobileImage?.altText || ``,
  };

  const smallImage = {
    data: block.smallImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.smallImage?.altText || ``,
  };

  let smallImageStyle = {
    bottom: '0',
    right: '0',
    maxWidth: '360px',
  }

  if (!!block.smallImageStyle.bottom) {
    smallImageStyle.bottom = block.smallImageStyle.bottom
  }
  if (!!block.smallImageStyle.right) {
    smallImageStyle.right = block.smallImageStyle.right
  }
  if (!!block.smallImageStyle.maxWidth) {
    smallImageStyle.maxWidth = block.smallImageStyle.maxWidth
  }

  const desktopGatsbyImage = !!desktopImage.data
    ? <GatsbyImage
      placeholder="none"
      loading="eager"
      image={desktopImage.data}
      alt={desktopImage.alt}
      className="mw-100" />
    : null;

  const mobileGatsbyImage = !!mobileImage.data
    ? <GatsbyImage
      placeholder="none"
      loading="eager"
      image={mobileImage.data}
      alt={mobileImage.alt}
      className="mw-100" />
    : desktopGatsbyImage;

  return (
    <>
      <section className={s.hero}>
        <div className="container">
          {block?.topLinks?.length > 0 && <div className={s.topLinks}>
            {block?.topLinks?.map((item, i) => {
              if (item.current) {
                return <span className={`${s.topLink} ${s.current}`} key={i}>{parse(item.label)}</span>
              } else {
                return <Link to={item?.link?.url} target={item?.link?.target} className={`${s.topLink}`} key={i}>{parse(item?.link?.title)}</Link>
              }
            })}
          </div>}
          <div>
            {!!block.title && <>
              {!!block?.useH1
                ? <h1 className={`gradient-title ${s.title}`}>
                  {parse(block.title)}
                </h1>
                : <h2 className={`gradient-title ${s.title}`}>
                  {parse(block.title)}
                </h2>
              }
            </>
            }
            {(desktopGatsbyImage || mobileGatsbyImage || block.youtubeVideoId) && <div className="w-100 position-relative" style={{minHeight: '140px'}}>
              <div className='w-100 d-none d-lg-block text-center'>
                {false != bp?.lg && <>
                  {!!block.youtubeVideoId
                    ? <YoutubeVideo
                      videoId={block.youtubeVideoId}
                      image={desktopGatsbyImage}
                    >
                    </YoutubeVideo>
                    : desktopGatsbyImage
                  }
                </>}
              </div>
              <div className='w-100 d-lg-none text-center'>
                {true != bp?.lg && <>
                  {!!block.youtubeVideoId
                    ? <YoutubeVideo
                      videoId={block.youtubeVideoId}
                      image={mobileGatsbyImage}
                    >
                    </YoutubeVideo>
                    : mobileGatsbyImage
                  }
                </>}
              </div>
              <>
                {!!smallImage.data && <div className={s.smallImage} style={smallImageStyle}>
                  <GatsbyImage
                    placeholder="none"
                    loading="eager"
                    image={smallImage.data}
                    alt={smallImage.alt}
                    className="mw-100" />
                </div>}
              </>
            </div>}
            {!!block?.description && <div className={s.description}>{parse(block?.description)}</div>}
            {!!block?.link?.url && <div className="d-flex justify-content-center mt-3">
              <Link to={block?.link?.url} target={block?.link?.target} className="demo-button px-3">{block?.link?.title}</Link>
            </div>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
