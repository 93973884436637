import { graphql, navigate } from "gatsby";
import parse from "html-react-parser";
import React from "react";

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css";
import "../css/@wordpress/block-library/build-style/theme.css";

import BannerBlock from "../components/build/banner/banner";
import ContactForm from "../components/build/contactForm/contactForm";
import ContentWithIframe2 from "../components/build/contentWithIframe2/contentWithIframe2";
import ContentWithImage from "../components/build/contentWithImage/contentWithImage";
import ContentWithStats from '../components/build/contentWithStats/contentWithStats';
import CustomButtonBlock from "../components/build/customButton/customButton";
import Faq from "../components/build/faq/faq";
import Features from "../components/build/features/features";
import FeaturesSlider from "../components/build/featuresSlider/featuresSlider";
import Footer from '../components/build/footer/footer';
import FoundersBlock from "../components/build/founders/founders";
import Header from '../components/build/header/header';
import Hero from "../components/build/hero/hero";
import IconItemsBlock from '../components/build/iconItemsBlock/iconItemsBlock';
import ImageText from "../components/build/imageText/imageText";
import Integrations from "../components/build/integrations/integrations";
import IntegrationsGlobal from "../components/build/integrationsGlobal/integrationsGlobal";
import LocatedBlock from "../components/build/located/located";
import News from "../components/build/news/news";
import PartnersIcons from "../components/build/partnersIcons/partnersIcons";
import Percent from "../components/build/percent/percent";
import Percents from "../components/build/percents/percents";
import Pricing from "../components/build/pricing/pricing";
import ProductsGrid from "../components/build/productsGrid/productsGrid";
import QrMenuMaker from "../components/build/qrMenuMaker/qrMenuMaker";
import SingleDeviceBlock from "../components/build/singleDeviceBlock/singleDeviceBlock";
import Testimonial from "../components/build/testimonial/testimonial";
import Testimonials from "../components/build/testimonials/testimonials";
import TestimonialsAlt from "../components/build/testimonialsAlt/testimonialsAlt";
import TestimonialsNew from "../components/build/TestimonialsNew/TestimonialsNew";
import Title from "../components/build/title/title";
import ValuesBlock from "../components/build/values/values";
import ImageTextSlider from "../components/imageTextSlider/ImageTextSlider";
import Seo from '../components/seo';


const PageTemplate = ({ data: { page, author, site }, location }) => {

  const formRef = React.useRef(null);
  const scrollToForm = () => {
    if (!!formRef?.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate('/contact-us');
    }
  };

  // const schema = schemaBuilder({ page: page, author: author }, 'page', site);
  return (
    <>
      <Seo
        title={page.title}
        description={""}
        pageSeo={page}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      {
        !!page.acf_content.acfContent &&
        page.acf_content.acfContent.map((block, index) => {
          switch (block.__typename) {
            case 'WpPage_AcfContent_AcfContent_HeroBlock':
              return <Hero block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_ContentWithImage':
              return <ContentWithImage block={block} scrollToForm={scrollToForm} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_ContentWithIframe':
              return <ContentWithIframe2 block={block} scrollToForm={scrollToForm} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_ContentWithStats':
              return <ContentWithStats block={block} scrollToForm={scrollToForm} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_PartnersIcons':
              return <PartnersIcons block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_Percents':
              return <Percents block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_Percent':
              return <Percent block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_ImageText':
              return <ImageText block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_ImageTextSlider':
              return <ImageTextSlider block={block} scrollToForm={scrollToForm} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_GlobalIntegrationsBlock':
              return <IntegrationsGlobal block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_Faq':
              return <Faq block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_News':
              return <News block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_IntegrationsBlock':
              return <Integrations block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_ProductsGrid':
              return <ProductsGrid block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_FeaturesList':
              return <Features block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_FeaturesSlider':
              return <FeaturesSlider block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_QrMenuMakerBlock':
              return <QrMenuMaker block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_ValuesBlock':
              return <ValuesBlock block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_ContactForm':
              return <div ref={formRef} key={`block-${index}`} ><ContactForm block={block} /></div>
            case 'WpPage_AcfContent_AcfContent_FoundersBlock':
              return <FoundersBlock block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_LocatedBlock':
              return <LocatedBlock block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_CustomButton':
              return <CustomButtonBlock block={block} scrollToForm={scrollToForm} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_Banner':
              return <BannerBlock block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_Pricing':
              return <Pricing block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_TestimonialsBlock':
              return <Testimonials block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_SingleTestimonialBlock':
              return <Testimonial block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_SingleTestimonialAlt':
              return <TestimonialsAlt block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_TestimonialsNewBlock':
              return <TestimonialsNew block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_TitleBlock':
              return <Title block={block} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_GlobalSingleDeviceBlock':
              return <SingleDeviceBlock block={block} scrollToForm={scrollToForm} key={`block-${index}`} />
            case 'WpPage_AcfContent_AcfContent_IconItemsBlock':
              return <IconItemsBlock block={block} scrollToForm={scrollToForm} key={`block-${index}`} />

            default:
              return <section className="container d-none" style={{ 'wordBreak': 'break-word' }} key={`block-${index}`}>
                {block.__typename}
                {/* {JSON.stringify(block, null, 2)} */}
                <hr />
              </section>
          }
        })
      }
      <section className="container">
        {!!page.content && (
          <section>{parse(page.content)}</section>
        )}
      </section>
      <Footer></Footer>
    </>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById(
    $id: String!
    $authorId: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            extension
            publicURL
          }
          width
          height
        }
        twitterImage {
          localFile {
            extension
            publicURL
          }
          width
          height
        }
        opengraphType
      }
      acf_content {
        acfContent {
          ... on WpPage_AcfContent_AcfContent_HeroBlock {
            __typename
            topLinks {
              current
              label
              link {
                target
                title
                url
              }
            }
            title
            useH1
            youtubeVideoId
            description
            link {
              target
              title
              url
            }
            desktopImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            mobileImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            smallImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            smallImageStyle {
              bottom
              right
              maxWidth
            }
          }
          ... on WpPage_AcfContent_AcfContent_ContentWithImage {
            __typename
            title
            description
            reverse
            badge
            button {
              url
              title
              target
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            imageAfterItems {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            useQrMenuMakerButtons
            itemsTitle
            items {
              type
              title
              description
              icon {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_ContentWithIframe {
            __typename
            title
            description
            reverse
            badge
            button {
              url
              title
              target
            }
            iframe
          }
          ... on WpPage_AcfContent_AcfContent_ContentWithStats {
            __typename
            title
            description
            button {
              target
              title
              url
            }
            stats {
              value
              label
            }
          }
          ... on WpPage_AcfContent_AcfContent_PartnersIcons {
            __typename
            useLocal
            title
            icons {
              link {
                url
                title
                target
              }
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_Percents {
            __typename
            title
            items {
              percent
              description
            }
          }
          ... on WpPage_AcfContent_AcfContent_Percent {
            __typename
            vertical
            percent
            description
            button {
              target
              title
              url
            }
          }
          ... on WpPage_AcfContent_AcfContent_ImageText {
            __typename
            bgColor
            title
            useBigTitle
            description
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            imagePosition
          }
          ... on WpPage_AcfContent_AcfContent_ImageTextSlider {
            __typename
            title
            items {
              title
              description
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                  publicURL
                }
              }
              imagePosition
            }
            button {
              url
              title
              target
            }
          }
          ... on WpPage_AcfContent_AcfContent_GlobalIntegrationsBlock {
            __typename
          }
          ... on WpPage_AcfContent_AcfContent_GlobalSingleDeviceBlock {
            __typename
          }
          ... on WpPage_AcfContent_AcfContent_Faq {
            __typename
            title
            items {
              question
              answer
            }
          }
          ... on WpPage_AcfContent_AcfContent_News {
            __typename
            title
            showFromCategory
            categoty {
              posts {
                nodes {
                  excerpt
                  uri
                  date(formatString: "MMMM DD, YYYY")
                  title
                  excerpt
                  readingTime {
                    text
                  }
                  categories {
                    nodes {
                      name
                      uri
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData( quality: 100 )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_IntegrationsBlock {
            __typename
            title
            icons {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData( quality: 100 )
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_ProductsGrid {
            __typename
            title
            items {
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
              link {
                url
                target
              }
              text
              isAi
            }
          }
          ... on WpPage_AcfContent_AcfContent_FeaturesList {
            __typename
            title
            items {
              title
              description
            }
          }
          ... on WpPage_AcfContent_AcfContent_FeaturesSlider {
            __typename
            title
            showNumbers
            items {
              backgroundColor
              textColor
              title
              description
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_QrMenuMakerBlock {
            __typename
          }
          ... on WpPage_AcfContent_AcfContent_ValuesBlock {
            __typename
            title
            items {
              text
            }
          }
          ... on WpPage_AcfContent_AcfContent_ContactForm {
            __typename
            showTitle
            title
            thankYou {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
              text
            }
          }
          ... on WpPage_AcfContent_AcfContent_FoundersBlock {
            __typename
            founders {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
              name
              socialUrl
              position
            }
          }
          ... on WpPage_AcfContent_AcfContent_LocatedBlock {
            __typename
            title
            description
            desktopImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            mobileImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_CustomButton {
            __typename
            type
            layout
            link {
              url
              title
              target
            }
            buttonText
          }
          ... on WpPage_AcfContent_AcfContent_Banner {
            __typename
            title
            link {
              url
              title
              target
            }
          }
          ... on WpPage_AcfContent_AcfContent_Pricing {
            __typename
            featuresLabel
            essentialLabel
            essentialPrice
            professionalLabel
            professionalPrice
            growthLabel
            growthPrice
            features {
              name
              essential
              essentialText
              professional
              professionalText
              growth
              growthText
            }
          }
          ... on WpPage_AcfContent_AcfContent_TestimonialsBlock {
            __typename
            title
            items {
              testimonial {
                ... on WpTestimonial {
                  title
                  featuredImage {
                    node {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                      }
                    }
                  }
                  testimonial {
                    review
                    authorName
                    position
                    link {
                      url
                      title
                      target
                    }
                    badge
                    badges {
                      title
                      text
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_SingleTestimonialBlock {
            __typename
            title
            bgColor
            testimonial {
              ... on WpTestimonial {
                title
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
                testimonial {
                  review
                  authorName
                  place
                  link {
                    url
                    title
                    target
                  }
                  badge
                  badges {
                    title
                    text
                  }
                  position
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_SingleTestimonialAlt {
            __typename
            title
            leftItems {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
              text
              className
            }
            rightBackgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            testimonial {
              ... on WpTestimonial {
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
                testimonial {
                  review
                  authorName
                  position
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_TestimonialsNewBlock {
            __typename
            title
            bgColor
            items {
              testimonial {
                ... on WpTestimonial {
                  title
                  featuredImage {
                    node {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                      }
                    }
                  }
                  testimonial {
                    review
                    authorName
                    place
                    link {
                      url
                      title
                      target
                    }
                    badge
                    badges {
                      title
                      text
                    }
                    position
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_TitleBlock {
            __typename
            useH1
            title
          }
          ... on WpPage_AcfContent_AcfContent_IconItemsBlock {
            __typename
            orangeBoxes
            title
            items {
              svgIconCode
              title
              description
            }
            button {
              target
              title
              url
            }
          }
        }
      }
    }
    author: wpUser(id: {eq: $authorId}) {
      avatar {
        size
        url
      }
      name
      description
      email
      slug
      uri
      seo {
        metaDesc
        social {
          facebook
          linkedIn
          twitter
          wikipedia
        }
      }
    }
  }
`