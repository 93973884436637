import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import Marquee from "react-fast-marquee";
import * as s from "./partnersIcons.module.css";

const PartnersIcons = ({block}) => {

  const { wp: { acfOptionsPartners: { partnersIcons: { partnersIconsBlock } } } } = useStaticQuery(graphql`
      query MyOptionsPartnersQuery {
        wp {
          acfOptionsPartners {
            partnersIcons {
              partnersIconsBlock {
                title
                icons {
                  link {
                    url
                    title
                    target
                  }
                  icon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);

    let data = partnersIconsBlock;
    if(!!block?.useLocal) {
      data = block;
    }

  return (
    <>
      <section className={`${s.partners} `}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!!data?.title && <h2 className={s.title}>{parse(data.title)}</h2>}
            </div>
          </div>
        </div>
        <div className="container-fluid px-0">
          <Marquee pauseOnHover={false} speed={60} className={`overflow-hidden`}>
            {data?.icons?.length > 0 && data?.icons?.map((item, i) => {
              return <PartnersItem item={item} key={i} />
            })}
          </Marquee>
        </div>
      </section >
    </>
  );
};

export default PartnersIcons;

const PartnersItem = ({ item }) => {
  const icon = {
    data: item.icon?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item.icon?.altText || ``,
  }
  if (!icon?.data) {
    return <></>;
  }
  if (!!item?.link?.url) {
    return <Link to={item?.link?.url} className={s.partnersItem}>
      <GatsbyImage
        placeholder="none"
        loading="eager"
        image={icon.data}
        alt={icon.alt}
        className="w-auto" />
    </Link>
  }
  return <span className={s.partnersItem}>
    <GatsbyImage
      placeholder="none"
      loading="eager"
      image={icon.data}
      alt={icon.alt}
      className="w-auto" />
  </span>
}

