import { Link } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./testimonial.module.css";

const Testimonial = ({ block }) => {
  const bp = useBreakpoint();


  const image = {
    data: block?.testimonial?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block?.testimonial?.featuredImage?.node?.altText || ``,
  }

  let styles={}

  if (!!block?.bgColor) {
    styles['--bg-color'] = block?.bgColor;
  }

  return (
    <>
      <section className={`${s.testimonialBlock}`} style={styles}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              {!!block?.title && <h2 className={`gradient-title ${s.title}`}>{parse(block?.title)}</h2>}
            </div>
            <div className="col-12 col-md-4 col-lg-3 d-flex align-items-center justify-content-center justify-content-md-left">
              {!!image.data && <GatsbyImage
                placeholder="none"
                loading="eager"
                image={image.data}
                alt={image.alt}
                className="mw-100" />}
            </div>
            <div className="col-12 col-md-7 col-lg-8 offset-md-1 mt-4 mt-md-0">
              {!!block?.testimonial?.testimonial?.place && <div className={s.place}>{parse(block?.testimonial?.testimonial?.place)}</div>}
              {(!!block?.testimonial?.testimonial?.review) && <div className={s.review}>{parse(block?.testimonial?.testimonial?.review)}</div>}
              <div className={s.person}>
                {parse(block?.testimonial?.testimonial?.authorName ?? '')}
                {(!!block?.testimonial?.testimonial?.authorName && !!block?.testimonial?.testimonial?.position) && ' - '}
                {parse(block?.testimonial?.testimonial?.position ?? '')}
                </div>
              {/* <Stars /> */}
            </div>
            {/* {!bp?.md && <div className="col-12">
              {!!block?.testimonial?.testimonial?.review && <div className={s.review}>{parse(block?.testimonial?.testimonial?.review)}</div>}
            </div>} */}
            {!!block?.testimonial?.testimonial?.link?.url &&
              <div className="col-12 mt-4 mt-md-5">
                <div className="d-flex justify-content-center justify-content-md-end">
                  <Link to={block?.testimonial?.testimonial?.link?.url} className='more-link' target={block?.testimonial?.testimonial?.link?.target}>{block?.testimonial?.testimonial?.link?.title}</Link>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
};

const Stars = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="128" height="25" viewBox="0 0 128 25" fill="none">
    <path d="M38.3916 24.4968C36.7781 24.4968 35.2629 24.1897 33.846 23.5754C32.429 22.9611 31.18 22.1134 30.0989 21.0323C29.0177 19.9512 28.166 18.7022 27.5435 17.2852C26.9292 15.8601 26.6221 14.3408 26.6221 12.7273C26.6221 11.122 26.9292 9.61087 27.5435 8.19394C28.1578 6.76882 29.0055 5.51569 30.0866 4.43457C31.1759 3.34525 32.429 2.49345 33.846 1.87918C35.2629 1.2649 36.774 0.957764 38.3793 0.957764C39.9928 0.957764 41.508 1.2649 42.925 1.87918C44.3419 2.49345 45.5909 3.34525 46.672 4.43457C47.7614 5.51569 48.6132 6.76882 49.2274 8.19394C49.8499 9.61087 50.1611 11.122 50.1611 12.7273C50.1611 14.3408 49.854 15.8601 49.2397 17.2852C48.6254 18.7022 47.7736 19.9512 46.6843 21.0323C45.6032 22.1134 44.3542 22.9611 42.9372 23.5754C41.5203 24.1897 40.0051 24.4968 38.3916 24.4968ZM34.9271 18.8946L38.3916 16.3638L41.8561 18.8946C42.0691 19.0502 42.2738 19.1117 42.4704 19.0789C42.6752 19.038 42.8267 18.9315 42.925 18.7595C43.0232 18.5875 43.0273 18.3704 42.9372 18.1084L41.5736 14.0296L45.0749 11.5233C45.2715 11.3841 45.3821 11.2121 45.4066 11.0073C45.4394 10.8026 45.3903 10.6224 45.2592 10.4668C45.1364 10.3111 44.9357 10.2374 44.6572 10.2456L40.3573 10.2579L39.0427 6.15454C38.969 5.91702 38.8421 5.75731 38.6619 5.6754C38.4817 5.5935 38.3015 5.5935 38.1213 5.6754C37.9411 5.75731 37.8142 5.91702 37.7405 6.15454L36.4259 10.2579L32.1383 10.2456C31.868 10.2374 31.6632 10.3111 31.524 10.4668C31.3929 10.6224 31.3397 10.8026 31.3643 11.0073C31.3889 11.2121 31.5035 11.3882 31.7083 11.5356L35.2097 14.0296L33.846 18.1084C33.7641 18.3704 33.7722 18.5875 33.8705 18.7595C33.9688 18.9315 34.1162 19.038 34.3128 19.0789C34.5094 19.1117 34.7141 19.0502 34.9271 18.8946Z" fill="#FF8A00" />
    <path d="M12.749 24.4968C11.1355 24.4968 9.62031 24.1897 8.20338 23.5754C6.78645 22.9611 5.53742 22.1134 4.4563 21.0323C3.37517 19.9512 2.52337 18.7022 1.90091 17.2852C1.28663 15.8601 0.979492 14.3408 0.979492 12.7273C0.979492 11.122 1.28663 9.61087 1.90091 8.19394C2.51518 6.76882 3.36288 5.51569 4.44401 4.43457C5.53333 3.34525 6.78645 2.49345 8.20338 1.87918C9.62031 1.2649 11.1314 0.957764 12.7367 0.957764C14.3502 0.957764 15.8655 1.2649 17.2824 1.87918C18.6993 2.49345 19.9483 3.34525 21.0295 4.43457C22.1188 5.51569 22.9706 6.76882 23.5849 8.19394C24.2073 9.61087 24.5186 11.122 24.5186 12.7273C24.5186 14.3408 24.2114 15.8601 23.5971 17.2852C22.9829 18.7022 22.1311 19.9512 21.0418 21.0323C19.9606 22.1134 18.7116 22.9611 17.2947 23.5754C15.8777 24.1897 14.3625 24.4968 12.749 24.4968ZM9.28451 18.8946L12.749 16.3638L16.2135 18.8946C16.4265 19.0502 16.6312 19.1117 16.8278 19.0789C17.0326 19.038 17.1841 18.9315 17.2824 18.7595C17.3807 18.5875 17.3848 18.3704 17.2947 18.1084L15.931 14.0296L19.4323 11.5233C19.6289 11.3841 19.7395 11.2121 19.7641 11.0073C19.7968 10.8026 19.7477 10.6224 19.6166 10.4668C19.4938 10.3111 19.2931 10.2374 19.0146 10.2456L14.7147 10.2579L13.4002 6.15454C13.3264 5.91702 13.1995 5.75731 13.0193 5.6754C12.8391 5.5935 12.6589 5.5935 12.4787 5.6754C12.2986 5.75731 12.1716 5.91702 12.0979 6.15454L10.7833 10.2579L6.49569 10.2456C6.22541 10.2374 6.02065 10.3111 5.88142 10.4668C5.75037 10.6224 5.69713 10.8026 5.7217 11.0073C5.74627 11.2121 5.86094 11.3882 6.0657 11.5356L9.56707 14.0296L8.20338 18.1084C8.12148 18.3704 8.12967 18.5875 8.22795 18.7595C8.32623 18.9315 8.47366 19.038 8.67023 19.0789C8.8668 19.1117 9.07156 19.0502 9.28451 18.8946Z" fill="#FF8A00" />
    <path d="M89.6787 24.4968C88.0652 24.4968 86.55 24.1897 85.1331 23.5754C83.7161 22.9611 82.4671 22.1134 81.386 21.0323C80.3049 19.9512 79.4531 18.7022 78.8306 17.2852C78.2163 15.8601 77.9092 14.3408 77.9092 12.7273C77.9092 11.122 78.2163 9.61087 78.8306 8.19394C79.4449 6.76882 80.2926 5.51569 81.3737 4.43457C82.463 3.34525 83.7161 2.49345 85.1331 1.87918C86.55 1.2649 88.0611 0.957764 89.6664 0.957764C91.2799 0.957764 92.7951 1.2649 94.2121 1.87918C95.629 2.49345 96.878 3.34525 97.9592 4.43457C99.0485 5.51569 99.9003 6.76882 100.515 8.19394C101.137 9.61087 101.448 11.122 101.448 12.7273C101.448 14.3408 101.141 15.8601 100.527 17.2852C99.9126 18.7022 99.0608 19.9512 97.9714 21.0323C96.8903 22.1134 95.6413 22.9611 94.2244 23.5754C92.8074 24.1897 91.2922 24.4968 89.6787 24.4968ZM86.2142 18.8946L89.6787 16.3638L93.1432 18.8946C93.3562 19.0502 93.5609 19.1117 93.7575 19.0789C93.9623 19.038 94.1138 18.9315 94.2121 18.7595C94.3104 18.5875 94.3144 18.3704 94.2244 18.1084L92.8607 14.0296L96.362 11.5233C96.5586 11.3841 96.6692 11.2121 96.6937 11.0073C96.7265 10.8026 96.6774 10.6224 96.5463 10.4668C96.4235 10.3111 96.2228 10.2374 95.9443 10.2456L91.6444 10.2579L90.3298 6.15454C90.2561 5.91702 90.1292 5.75731 89.949 5.6754C89.7688 5.5935 89.5886 5.5935 89.4084 5.6754C89.2282 5.75731 89.1013 5.91702 89.0276 6.15454L87.713 10.2579L83.4254 10.2456C83.1551 10.2374 82.9503 10.3111 82.8111 10.4668C82.6801 10.6224 82.6268 10.8026 82.6514 11.0073C82.676 11.2121 82.7906 11.3882 82.9954 11.5356L86.4968 14.0296L85.1331 18.1084C85.0512 18.3704 85.0594 18.5875 85.1576 18.7595C85.2559 18.9315 85.4033 19.038 85.5999 19.0789C85.7965 19.1117 86.0012 19.0502 86.2142 18.8946Z" fill="#FF8A00" />
    <path d="M64.0352 24.4968C62.4217 24.4968 60.9064 24.1897 59.4895 23.5754C58.0726 22.9611 56.8236 22.1134 55.7424 21.0323C54.6613 19.9512 53.8095 18.7022 53.187 17.2852C52.5728 15.8601 52.2656 14.3408 52.2656 12.7273C52.2656 11.122 52.5728 9.61087 53.187 8.19394C53.8013 6.76882 54.649 5.51569 55.7301 4.43457C56.8195 3.34525 58.0726 2.49345 59.4895 1.87918C60.9064 1.2649 62.4176 0.957764 64.0229 0.957764C65.6364 0.957764 67.1516 1.2649 68.5685 1.87918C69.9854 2.49345 71.2345 3.34525 72.3156 4.43457C73.4049 5.51569 74.2567 6.76882 74.871 8.19394C75.4935 9.61087 75.8047 11.122 75.8047 12.7273C75.8047 14.3408 75.4975 15.8601 74.8833 17.2852C74.269 18.7022 73.4172 19.9512 72.3279 21.0323C71.2468 22.1134 69.9977 22.9611 68.5808 23.5754C67.1639 24.1897 65.6487 24.4968 64.0352 24.4968ZM60.5706 18.8946L64.0352 16.3638L67.4997 18.8946C67.7126 19.0502 67.9174 19.1117 68.1139 19.0789C68.3187 19.038 68.4702 18.9315 68.5685 18.7595C68.6668 18.5875 68.6709 18.3704 68.5808 18.1084L67.2171 14.0296L70.7185 11.5233C70.915 11.3841 71.0256 11.2121 71.0502 11.0073C71.083 10.8026 71.0338 10.6224 70.9028 10.4668C70.7799 10.3111 70.5792 10.2374 70.3008 10.2456L66.0008 10.2579L64.6863 6.15454C64.6126 5.91702 64.4856 5.75731 64.3054 5.6754C64.1252 5.5935 63.9451 5.5935 63.7649 5.6754C63.5847 5.75731 63.4577 5.91702 63.384 6.15454L62.0695 10.2579L57.7818 10.2456C57.5115 10.2374 57.3068 10.3111 57.1675 10.4668C57.0365 10.6224 56.9833 10.8026 57.0078 11.0073C57.0324 11.2121 57.1471 11.3882 57.3518 11.5356L60.8532 14.0296L59.4895 18.1084C59.4076 18.3704 59.4158 18.5875 59.5141 18.7595C59.6124 18.9315 59.7598 19.038 59.9564 19.0789C60.1529 19.1117 60.3577 19.0502 60.5706 18.8946Z" fill="#FF8A00" />
    <path d="M115.321 24.4968C113.708 24.4968 112.193 24.1897 110.776 23.5754C109.359 22.9611 108.11 22.1134 107.029 21.0323C105.947 19.9512 105.096 18.7022 104.473 17.2852C103.859 15.8601 103.552 14.3408 103.552 12.7273C103.552 11.122 103.859 9.61087 104.473 8.19394C105.087 6.76882 105.935 5.51569 107.016 4.43457C108.106 3.34525 109.359 2.49345 110.776 1.87918C112.193 1.2649 113.704 0.957764 115.309 0.957764C116.923 0.957764 118.438 1.2649 119.855 1.87918C121.272 2.49345 122.521 3.34525 123.602 4.43457C124.691 5.51569 125.543 6.76882 126.157 8.19394C126.78 9.61087 127.091 11.122 127.091 12.7273C127.091 14.3408 126.784 15.8601 126.169 17.2852C125.555 18.7022 124.703 19.9512 123.614 21.0323C122.533 22.1134 121.284 22.9611 119.867 23.5754C118.45 24.1897 116.935 24.4968 115.321 24.4968ZM111.857 18.8946L115.321 16.3638L118.786 18.8946C118.999 19.0502 119.204 19.1117 119.4 19.0789C119.605 19.038 119.756 18.9315 119.855 18.7595C119.953 18.5875 119.957 18.3704 119.867 18.1084L118.503 14.0296L122.005 11.5233C122.201 11.3841 122.312 11.2121 122.336 11.0073C122.369 10.8026 122.32 10.6224 122.189 10.4668C122.066 10.3111 121.865 10.2374 121.587 10.2456L117.287 10.2579L115.972 6.15454C115.899 5.91702 115.772 5.75731 115.592 5.6754C115.411 5.5935 115.231 5.5935 115.051 5.6754C114.871 5.75731 114.744 5.91702 114.67 6.15454L113.356 10.2579L109.068 10.2456C108.798 10.2374 108.593 10.3111 108.454 10.4668C108.323 10.6224 108.269 10.8026 108.294 11.0073C108.319 11.2121 108.433 11.3882 108.638 11.5356L112.139 14.0296L110.776 18.1084C110.694 18.3704 110.702 18.5875 110.8 18.7595C110.899 18.9315 111.046 19.038 111.242 19.0789C111.439 19.1117 111.644 19.0502 111.857 18.8946Z" fill="#FF8A00" />
  </svg>
}

export default Testimonial;
